import { pushDataLayer } from './dataLayer'

/**
 * Tracks a coupon code event and sends data to the dataLayer.
 *
 * @param {Object} couponData - The data of the coupon code.
 * @param {string} couponData.action - The action related to the coupon (e.g., "apply", "remove").
 * @param {string} couponData.coupon - The coupon code.
 */
function trackCouponCode(couponData) {
  if (couponData) {
    const tagManagerDataLayerArgs = {
      dataLayer: {
        event: 'cartCoupons',
        couponAction: couponData.action,
        couponCode: couponData.coupon,
      },
    }
    pushDataLayer(tagManagerDataLayerArgs)
  }
}

export default trackCouponCode
