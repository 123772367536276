import { getNumericPrice } from '../../index'

import { pushDataLayer, clearGA4Data } from './dataLayer'

/**
 * Tracks an 'Add to Cart' event in GTM and pushes relevant data to the data layer.
 *
 * @param {Object} productData - The product that has been added.
 * @param {number} qty - The relative number of items that have been added.
 * @param {Object} cartTotals - The total value of the cart.
 * @param {Object[]} cartItems - All items in the cart (including the added ones).
 * @param {string|number} specialPrice - The price shown to the user. Falls back to 'productData.price'.
 * @param {Object} trackingData - Additional properties to track.
 */
function trackAddToCart(
  productData,
  qty,
  cartTotals,
  cartItems,
  specialPrice,
  trackingData
) {
  const priceValue =
    getNumericPrice(specialPrice) || getNumericPrice(productData.price)

  const products =
    cartItems?.map((item) => {
      const { sku, name, finalPrice } = item.product
      return {
        sku,
        name,
        price: getNumericPrice(finalPrice),
      }
    }) || []

  const tagManagerDataLayerArgs = {
    dataLayer: {
      event: 'addToCart',
      ga4: {
        currencyCode: process.env.CURRENCY,
        totalValue: priceValue * qty,
        items: [
          {
            item_id: productData.sku,
            item_name: productData.title,
            item_variant: productData.config_variable_a,
            price: priceValue,
            quantity: qty,
          },
        ],
        ...(trackingData || {}),
      },
      ecommerce: {
        currencyCode: process.env.CURRENCY,
        totalValue: priceValue * qty,
        add: {
          products: [
            {
              id: productData.id,
              sku: productData.sku,
              name: productData.title,
              brand: productData.manufacturer_name,
              variant: productData.config_variable_a,
              price: specialPrice,
              quantity: qty,
              currencyCode: process.env.CURRENCY,
            },
          ],
        },
        cart: {
          products: products,
        },
        cartTotalUpdate: cartTotals,
        cartGrandTotal: getNumericPrice(cartTotals),
      },
    },
  }
  clearGA4Data()
  pushDataLayer(tagManagerDataLayerArgs)
}

export default trackAddToCart
