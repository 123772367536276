import * as Yup from 'yup'

import { ProductData } from './product'
import {
  ShippingOptions,
  PaymentMethod,
  CartTotals,
  CartProduct,
  CartItem,
  CartData,
  AddToCartData,
  SetCouponData,
  SetCartItemQtyData,
  SetCartItemAboStatusData,
} from './cart'
import {
  UserData,
  LoginData,
  RegisterData,
  ForgotPasswordData,
  NewPasswordData,
  CustomerAccountData,
  SetCustomerAccountData,
  ConfirmAccountStatusData,
} from './user'
import {
  OrderListData,
  OrderListRequestData,
  OrderDetailData,
  OrderDetailsRequestData,
  RegularDeliveryData,
  RegularDeliveryChangeData,
} from './order'
import { SuccessData } from './success'
import { StatusData } from './status'

const AddressCorrectionData = Yup.object()
  .shape({
    shipping: Yup.object().shape({
      name: Yup.string(),
      street: Yup.string(),
    }),
    paymentMethod: Yup.object().shape({
      name: Yup.string(),
      editUrl: Yup.string(),
    }),
    billing: Yup.object().shape({
      name: Yup.string(),
      street: Yup.string(),
    }),
  })
  .concat(StatusData)

export {
  StatusData,
  LoginData,
  RegisterData,
  ForgotPasswordData,
  NewPasswordData,
  UserData,
  CustomerAccountData,
  SetCustomerAccountData,
  ConfirmAccountStatusData,
  OrderListData,
  OrderListRequestData,
  OrderDetailData,
  OrderDetailsRequestData,
  SuccessData,
  RegularDeliveryData,
  RegularDeliveryChangeData,
  CartData,
  CartItem,
  CartProduct,
  CartTotals,
  PaymentMethod,
  ShippingOptions,
  AddToCartData,
  SetCouponData,
  SetCartItemQtyData,
  SetCartItemAboStatusData,
  ProductData,
  AddressCorrectionData,
}
