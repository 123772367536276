import { getNumericPrice } from '../../index'

import { pushDataLayer } from './dataLayer'

/**
 * Tracks a subscription toggle event and sends data to the dataLayer.
 *
 * @param {string} sku - The SKU of the item.
 * @param {number} aboPrice - The price of the item for the subscription.
 * @param {boolean} isAboItem - Indicates whether the item is selected for subscription.
 */
function trackSubscriptionToggle(sku, aboPrice, isAboItem) {
  const priceValue = getNumericPrice(aboPrice)
  const aboState = isAboItem ? 'selected' : 'deselected'
  const tagManagerDataLayerArgs = {
    dataLayer: {
      event: 'aboSelected',
      subscribtion: {
        sku,
        aboPrice: priceValue,
        aboState,
      },
    },
  }
  pushDataLayer(tagManagerDataLayerArgs)
}

export default trackSubscriptionToggle
