import { getNumericPrice } from '../../index'

import { pushDataLayer, clearGA4Data } from './dataLayer'

/**
 * Fires the 'cartRemoveItem' event when an item has been removed completely from the cart.
 * This should not be fired when an item quantity has been reduced and the product itself is still in the cart!
 *
 * @param {Object} cartProduct - The item that has been removed from the cart.
 * @param {Object[]} cartItems - All items in the cart.
 * @param {Object[]} totals - The total values of the cart.
 * @param {number} oldQty - The previous quantity of the item before it was removed.
 */
function trackCartItemRemoved(cartProduct, cartItems, totals, oldQty) {
  if (cartProduct) {
    const products =
      cartItems?.map((item) => {
        return {
          sku: item.sku,
          name: item.name,
          price: getNumericPrice(item.finalPrice),
        }
      }) || []
    const pricePerItem = getNumericPrice(cartProduct.finalPrice)
    const tagManagerDataLayerArgs = {
      dataLayer: {
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [
              {
                id: cartProduct.id,
                sku: cartProduct.sku,
                name: cartProduct.name,
                brand: cartProduct.manufacturer,
                variant: cartProduct.config_variable_a,
                price: cartProduct.price,
                quantity: oldQty,
              },
            ],
          },
          cart: {
            products: products,
          },
        },
        ga4: {
          currencyCode: process.env.CURRENCY,
          totalValue: pricePerItem * oldQty,
          items: [
            {
              item_id: cartProduct.sku,
              item_name: cartProduct.name,
              item_variant: cartProduct.config_variable_a,
              price: pricePerItem,
              quantity: oldQty,
            },
          ],
        },
      },
    }

    clearGA4Data()
    pushDataLayer(tagManagerDataLayerArgs)
  }
}

export default trackCartItemRemoved
