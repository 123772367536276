import React, { useEffect, useRef, useState } from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'

import {
  fetchRatings,
  useCarouselSettings,
  useFeatureFlags,
  useMediaResize,
} from '../../../utils'
import { Carousel } from '../..'
import { syncRefHeight } from './syncRefHeight'
import RowCard from './RowCard'
import ColumnCard from './ColumnCard'

const carouselBreakpoints = {
  desktop: {
    breakpoint: { max: Number.MAX_VALUE, min: 1200 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1199.98, min: 160 },
    items: 1,
  },
}

const ProductOffers = ({
  products,
  cards,
  avoidCarousel,
  // Rest props goes into ProductCard component
  ...cardProps
}) => {
  const [productRatings, setProductRatings] = useState({})
  const [isNoCarousel, setIsNoCarousel] = useState(false)
  const { breakpoints } = useMediaResize()
  const { isFeatureEnabled } = useFeatureFlags()
  const benefitRefs = useRef([])
  const descriptionRefs = useRef([])
  const descriptionChildRefs = useRef([])
  const carouselSettings = useCarouselSettings('product-offers')

  const isNoCarouselMode =
    avoidCarousel && products.length > 1 && products.length < 4
  const isNoCarouselModeUpLG = isNoCarouselMode && breakpoints.upLG

  const carouselClasses = classNames(
    'overflow-hidden',
    'react-multi-carousel-list',
    products.length === 1 && 'justify-content-lg-center'
  )

  const noCarouselContainerClasses = classNames(
    isNoCarouselMode &&
      'd-flex align-items-center justify-content-center product-offers__no-carousel'
  )

  useEffect(() => {
    let isMounted = true

    if (isFeatureEnabled('product.ratings')) {
      fetchRatings(products)
        .then((items) => {
          if (isMounted) setProductRatings(items)
        })
        .catch(console.error)
    }

    return () => {
      isMounted = false
    }
  }, [isFeatureEnabled, products])

  useEffect(() => {
    // creates height for description sections based on the highest child section
    // (info or action section). Works for desktop version.
    if (
      (isNoCarouselModeUpLG && descriptionChildRefs.current.length) ||
      descriptionRefs.current.length
    ) {
      syncRefHeight(
        descriptionChildRefs.current,
        descriptionRefs.current,
        breakpoints.downXL
      )
    }

    // creates same height for each benefit section
    if (benefitRefs.current.length) {
      syncRefHeight(benefitRefs.current)
    }
  }, [
    isNoCarouselModeUpLG,
    breakpoints.downSM,
    breakpoints.downMD,
    breakpoints.downXL,
    benefitRefs,
    isNoCarousel,
  ])

  useEffect(() => {
    // Fix for initial classes of carousel container
    setIsNoCarousel(isNoCarouselModeUpLG || products.length === 1)
  }, [isNoCarouselModeUpLG, products.length])

  const renderCards = (productData, i, arr) => {
    const itemRating = productRatings?.[productData.id] || null
    const createBenefitRef = (el) => (benefitRefs.current[i] = el)
    const createDescriptionRefs = (el) =>
      isNoCarouselModeUpLG ? (descriptionRefs.current[i] = el) : []
    const createDescriptionChildRefs = (isDescriptionAction) => (el) => {
      if (isNoCarouselModeUpLG) {
        return (descriptionChildRefs.current[
          isDescriptionAction ? i + arr.length : i
        ] = el)
      }
      return []
    }

    const Card =
      isNoCarouselModeUpLG || breakpoints.downSM ? ColumnCard : RowCard
    const productCardProps = {
      ratingSummary: itemRating,
      key: `product_${productData.id}_${i}_${
        isNoCarouselModeUpLG ? 'no-carousel' : 'carousel'
      }`,
      arrLength: arr.length,
      benefitRef: createBenefitRef,
      descriptionRef: createDescriptionRefs,
      descriptionChildRef: createDescriptionChildRefs,
      productData,
      ...cards[i],
      ...cardProps,
    }

    return <Card {...productCardProps} />
  }

  if (products.length) {
    return (
      <section className="product-offers position-relative container">
        {isNoCarousel ? (
          <div className={noCarouselContainerClasses}>
            {products.slice(0, 3).map(renderCards)}
          </div>
        ) : (
          <Carousel
            responsive={carouselBreakpoints}
            className={carouselClasses}
            pauseOnHover={true}
            autoPlaySpeed={3000}
            renderDotsOutside={true}
            rewind={true}
            rewindWithAnimation={true}
            infinite={products.length > 1}
            {...carouselSettings}
          >
            {products.map(renderCards)}
          </Carousel>
        )}
      </section>
    )
  }

  return null
}

ProductOffers.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  cards: PropTypes.arrayOf(PropTypes.object),
  avoidCarousel: PropTypes.bool,
}

ProductOffers.defaultProps = {
  products: [],
  cards: [],
  avoidCarousel: false,
}

export default ProductOffers
export { default as productOffersVariants } from './variants.js'
